import { module } from 'modujs';
import 'owl.carousel';

export default class extends module {
  constructor(m) {
    super(m);
  }

  init() {
    $('.c-full-slider__slider-content').owlCarousel({
      items: 1,
      lazyLoad: false,
      loop: true,
      margin: 0,
      nav: false,
      dots: true,
      autoHeight: true,
      mouseDrag: true,
      // animateOut: 'fadeOut'


    });




  }



  destroy() {
    super.destroy();
  }

}


