import { module } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
      setTimeout(() => {
       this.scroll = new LocomotiveScroll({
          el: this.el,
          smooth: false,
          getSpeed: true,
          getDirection: true
      });

      }, 500);
    }



    destroy() {
      this.scroll.destroy();
    }
}
