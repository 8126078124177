import { module } from 'modujs';
import '../vendors/modal';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
      $('#talent').magnificPopup({
        items: {
            src: 'https://crm.recruiting.com/community-widget.asp?id=community-health-choice001'
        },
        type: 'iframe'
    });

    $('#about-video').magnificPopup({
      items: {
          src: 'https://www.youtube.com/watch?v=TdVvudcDpWU'
      },
      type: 'iframe'
  });

    }
}
